<script>
const messages = {
  en: {
    message: {
      home: {
        menu: "Home",
        hello: "hello HOME",
        cardF1: "Many championship with the years",
        cardACC: "Championship for all, for all king level of driver.",
        cardAMS2: "Championship of 2 in 2 months with many categories.",
        cardIR:
          "Experienced drivers willing to help you take it to another level.",
        cardRF2: "The most varied types of championships.",
        cardDR2: "For those who like to get their clothes dirty in the mud.",
        buttonCard: "Get more information",
        titleCard: "Championship",
      },
      about: {
        menu: "About",
        hello: "About FNM",
        text: "Welcome to the FNM Racing Team website! It all started when a bunch of people decided to participate in a legendary league of F1 2020 (Codemasters). However, the problems of the organization of this  league have already started to arise even before the championship started, which ended up forming a “small group” of pilots against the organization itself. The fact is that this  league really proved to be an organizational failure, showing a lack of knowledge in organization skills. All of this ended up becoming a foundamental stone to form a new virtual motorsport group, with mutual interests and thoughts. Here comes FNM, an AV group focused on fun and share experiences, along with championships and 4funs from the most diverse simulators in the AV world. Do you want to know the full story?  Do you want to know the meaning of FNM brand? Then board with us!",
        slide: {
          1: "1st Kart at Betim/MG",
          2: "Barbecue at the meeting in Betim",
          3: "2nd Kart in Vespasiano/MG",
          4: "2nd Kart in Vespasiano/MG",
          5: "Barbecue after the kart in Vespasiano/MG",
          6: "3rd kart in Sao Paulo",
          7: "F1 GP in 2021 - SP",
          8: "F1 GP in 2021 - SP",
        },
      },
      championships: {
        menu: "Championships",
        hello: "Our Championships",
      },
      contact: {
        menu: "Contact",
        hello: "Contacts",
        text: "Hello, to contact us, look for us in our whatsapp groups, we are always willing to help and answer any questions about FNM and our championships",
        generalGroup: "General group",
        amsGroup: "AMS2 group",
        email: "Email address",
        emailDescription: "We'll never share your email with anyone else.",
        emailPlaceholder: "Enter email",
        name: "Your Name",
        namePlaceholder: "Enter name",
        games: "Games of Interest",
      },
      drivers: {
        menu: "Drivers",
        hello: "Our Drivers",
      },
      liveTiming: {
        menu: "Live-Timing",
      },
      utils: {
        socialMedia: "Social Media",
      },
      games: {
        frequency: "Frequency",
        f1: {
          text: "We could not leave aside the most important category of motorsport! Using the Codemasters game as the basis of our Formula 1 championships, we have several stages and levels throughout the year. Always valuing for fun and high competitiveness! Consecrated pilots (and others not so much...) have passed (and still pass) here at FNM! Come be part of this family of Formula 1 lovers!",
          date: "Wednesday 21h",
        },
        acc: {
          text: "For many, the best simulator today! For those who like the GT3, GT4, GTC and TCX categories, there is no game like ACC that can offer us the best simulation experience! And, of course, here at FNM we also have 4funs monthly and soon we will organize a higher level championship! You are invited to explore the difficulty of Mount Panorama with us!",
          date: "Tuesday 21:30",
        },
        ams2: {
          text: "Perhaps the most versatile of simulators! Here at FNM we have championships every 2 months, varying the category with each championship! Formula 3, Montana Cup, Stock Car, Sprint, GTs and several other categories are in our portfolio! Diverse teams from here in Brazil are always participating in our championships, where we value the fun in the first place!",
          date: "Monday 20h30",
        },
        ir: {
          text: "We are also in the most glamorous simulator in AV! If you like a high degree of challenge, there is no game that can offer this challenge like iRacing! Here at FNM we participate in daily events and soon we will organize a super championship!",
          date: "Daily",
        },
        rf2: {
          text: "A complete simulator where at times we try to hold some events, unfortunately due to the low demand we were not able to make a great event worthy of transmission as this simulator would deserve, but as interested parties appear we can create a new championship.",
          date: "Quinta Feira 22h",
        },
        dr2: {
          text: "One of the best and perhaps the best rally simulator today, we managed to create an entry championship where we had some interested parties, however, with a very full calendar we were unable to continue the event, but if you are interested, contact us and we will stay happy to try to organize a new championship",
          date: "Quinta Feira 22h",
        },
      },
    },
  },
  pt_BR: {
    message: {
      home: {
        menu: "Home",
        hello: "OLA inicio",
        cardF1: "Vários campeonatos de Formula 1 durante o ano.",
        cardACC: "Torneios para todos os tipos e níveis de pilotos.",
        cardAMS2: "Torneios de 2 em 2 meses com várias categorias.",
        cardIR:
          "Pilotos experientes dispostos a te ajudar a subir para outro nível.",
        cardRF2: "Os mais variados tipos de campeonatos.",
        cardDR2: "Para aqueles que gostam de sujar a roupa na lama.",
        buttonCard: "Obter mais informações",
        titleCard: "Campeonatos",
      },
      about: {
        menu: "Sobre",
        hello: "Sobre a FNM",
        text: "Bem vindo ao site da FNM Racing Team! Tudo começou quando um grupo de desavisados resolveu participar de uma liga lendária de F1 2020 (Codemasters). Porém, os problemas da organização dessa liga lendária já começaram a surgir antes mesmo de começar o campeonato, o que acabou formando uma “panelinha” de pilotos contra essa mesma (des)organização. O fato é que essa liga lendária se mostrou realmente um fiasco de organização, mostrando muito amadorismo em coisas simples. Tudo isso acabou fazendo com que essa “panelinha” formasse um novo grupo de automobilismo virtual, com interesses e pensamentos mútuos. Eis que surge a FNM, grupo de AV focado na diversão e resenha, junto com campeonatos e 4funs dos mais diversos simuladores do mundo AV. Você quer saber da história completa? Quer saber o significado da sigla FNM? Então embarque com a gente!",
        slide: {
          1: "1º Kart em Betim/MG",
          2: "Churrasco no encontro em Betim",
          3: "2º Kart em Vespasiano/MG",
          4: "2º Kart em Vespasiano/MG",
          5: "Churrasco após o kart em Vespasiano/MG",
          6: "3º kart em São Paulo",
          7: "GP de F1 em 2021 - SP",
          8: "GP de F1 em 2021 - SP",
        },
      },
      championships: {
        menu: "Campeonatos",
        hello: "Nossos Campeonatos",
      },
      contact: {
        menu: "Contato",
        hello: "Contato",
        text: "Olá, para entrar em contato conosco nos procure em nossos grupos de Whatsapp, estamos sempre dispostos a ajudar e tirar qualquer duvida sobre a FNM e nossos campeonatos",
        generalGroup: "Grupo Geral",
        amsGroup: "Grupo AMS2",
        email: "E-mail",
        emailDescription:
          "Nós não iremos compartilhar seu e-mail com outros lugares.",
        emailPlaceholder: "Informe seu e-mail",
        name: "Seu Nome",
        namePlaceholder: "Informe seu nome",
        cel: "Seu Celular",
        celPlaceholder: "Informe seu celular",
        games: "Jogos do seu interesse",
      },
      drivers: {
        menu: "Pilotos",
        hello: "Nossos Pilotos",
      },
      liveTiming: {
        menu: "Live-Timing",
      },
      utils: {
        socialMedia: "Mídias Sociais",
      },
      games: {
        frequency: "Frequencia",
        f1: {
          text: "Não poderíamos deixar de lado a categoria mais importante do automobilismo! Utilizando o game da Codemasters como base dos nossos campeonatos de Fórmula 1, contamos com diversas etapas e níveis ao longo do ano. Sempre prezando pela diversão e alta competitividade! Pilotos consagrados (e outros nem tanto...) já passaram (e ainda passam) aqui na FNM! Venha fazer parte dessa família de apaixonados por Fórmula 1!",
          date: "Quarta Feira 21h",
        },
        acc: {
          text: "Para muitos, o melhor simulador atualmente! Para quem gosta das categorias GT3, GT4, GTC e TCX, não existe um jogo igual ao ACC que pode nos oferecer a melhor experiência de simulação! E, claro, aqui na FNM também contamos com 4funs mensais e em breve organizaremos um campeonato de mais alto nível! Está convidado a desbravar a dificuldade do Monte Panorama conosco!",
          date: "Terça-feira 21:30",
        },
        ams2: {
          text: "Talvez o mais versátil dos simuladores! Aqui na FNM temos campeonatos de 2 em 2 meses, variando a categoria a cada campeonato! Fórmula 3, Copa Montana, Stock Car, Sprint, GTs e várias outras categorias estão no nosso portfólio! Equipes diversas daqui do Brasil estão sempre participando dos nossos campeonatos, onde prezamos pela diversão em primeiro lugar!",
          date: "Segunda feira 20h30",
        },
        ir: {
          text: "Também estamos no simulador mais glamouroso do AV! Se você gosta de um alto grau de desafio, não há game que pode oferecer esse desafio como o iRacing! Aqui na FNM participamos de eventos diários e em breve iremos organizar um super campeonato!",
          date: "Diariamente",
        },
        rf2: {
          text: "Um simulador completo onde em alguns momentos tentamos realizar alguns eventos, infelizmente pela pouca procura não conseguimos fazer um grande evento digno de transmissão como este simulador mereceria, mas conforme forem aparecendo interessados poderemos criar um novo campeonato.",
          date: "A combinar",
        },
        dr2: {
          text: "Um dos melhores e talvez o melhor simulador de rally da atualidade, conseguimos criar um campeonato de entrada onde tivemos alguns interessados, porém, com o calendário bastante cheio não conseguimos dar continuídade no evento, mas caso você tenha interesse, entre em contato conosco que ficaremos felizes em tentar organizar um novo campeonato",
          date: "A combinar",
        },
      },
    },
  },
};

export default {
  name: "Languages",
  messages,
};
</script>

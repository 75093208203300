<template>
  <div id="app">
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="dark"
      class="vh-50 text-center"
      align-v="center"
    >

      <b-navbar-brand href="/" class="col-9">
        <b-img left src="@/assets/fnm/branco2.png" class="logo-image logo-image-sm"></b-img>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class="col-2"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="nav-menu-fnm">
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            exact-active-class="active"
            class="menu-sup"
            v-for="routes in links"
            :key="routes.id"
            :to="routes.path"
          >
            {{ $t("message." + routes.name + ".menu") }}
          </b-nav-item>

          <b-nav-item
            exact-active-class="active"
            class="menu-sup"
            href="/live-timing/index.php"
          >
            Live-Timing ACC
          </b-nav-item>

          <b-nav-item>
            <b-img
              v-on:click="changeLang('pt_BR')"
              class="icon-image"
              right
              src="@/assets/brazil-circular.png"
            ></b-img>
          </b-nav-item>
          <b-nav-item>
            <b-img
              v-on:click="changeLang('en')"
              class="icon-image"
              right
              src="@/assets/eua-circular.png"
            ></b-img>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view :key="$route.path" />
    <footer class="bd-footer text-muted docs-border footer footer-sm">
      <b-container>
        <b-row>
          <b-col sm="6" md="3" class="margin-menu-footer-sm">
            <h5 class="text-footer-h5">Menus</h5>
            <ul class="list-unstyled">
              <li>
                <b-link class="link-footer" href="/">{{
                  $t("message.home.menu")
                }}</b-link>
              </li>
              <li>
                <b-link class="link-footer" href="/championships">{{
                  $t("message.championships.menu")
                }}</b-link>
              </li>
              <li>
                <b-link class="link-footer" href="/contact">{{
                  $t("message.contact.menu")
                }}</b-link>
              </li>
              <li>
                <b-link class="link-footer" href="/drivers">{{
                  $t("message.drivers.menu")
                }}</b-link>
              </li>
              <li>
                <b-link class="link-footer" href="/about">{{
                  $t("message.about.menu")
                }}</b-link>
              </li>
            </ul>
          </b-col>
          <b-col sm="6" md="3">
            <h5 class="text-footer-h5">Games</h5>
            <ul class="list-unstyled">
              <li><b-link class="link-footer" href="/games/f1">F1</b-link></li>
              <li>
                <b-link class="link-footer" href="/games/acc"
                  >Assetto Corsa Competizione</b-link
                >
              </li>
              <li>
                <b-link class="link-footer" href="/games/ams2"
                  >Automobilista 2</b-link
                >
              </li>
              <li>
                <b-link class="link-footer" href="/games/ir">iRacing</b-link>
              </li>
              <li>
                <b-link class="link-footer" href="/games/rf2">rFactor 2</b-link>
              </li>
              <li>
                <b-link class="link-footer" href="/games/dr2"
                  >Dirt Rally 2.0</b-link
                >
              </li>
            </ul>
            <h5 class="text-footer-h5 margin-menu-footer-sm">
              {{ $t("message.utils.socialMedia") }}
            </h5>
            <b-row class="footer-social footer-social-sm">
              <b-link
                href="https://web.facebook.com/fnmracing"
                target="_blank"
                title="Facebook"
                ><b-img
                  left
                  src="@/assets/social-media/fb.png"
                  class="logo-image-footer-social-media"
                ></b-img
              ></b-link>
              <b-link
                href="https://www.instagram.com/fnmracing/ "
                target="_blank"
                title="Instagram"
                ><b-img
                  left
                  src="@/assets/social-media/inst.png"
                  class="logo-image-footer-social-media"
                ></b-img
              ></b-link>
            </b-row>
          </b-col>
          <b-col sm="6" md="3" class="margin-menu-footer-sm">
            <h5 class="text-footer-h5">Apoio</h5>
            <a href="https://valleytec.com.br/" target="_blank"
              ><b-img
                left
                src="@/assets/home/support/valleytec.png"
                class="logo-image-footer-support"
              ></b-img
            ></a>
          </b-col>
          <b-col>
            <b-row>
              <b-img
                left
                src="@/assets/fnm/branco2.png"
                class="logo-image-footer"
              ></b-img>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<script>
export default {
  methods: {
    changeLang(lang) {
      if (localStorage.Lang != null) {
        if (lang !== localStorage.Lang) {
          localStorage.Lang = lang;
        }
        this.$i18n.locale = localStorage.Lang;
      } else {
        localStorage.Lang = lang;
        this.$i18n.locale = lang;
      }
    },
  },
  data() {
    return {
      links: [
        {
          id: 0,
          name: "home",
          path: "/",
        },
        {
          id: 1,
          name: "championships",
          path: "/championships",
        },
        {
          id: 2,
          name: "drivers",
          path: "/drivers",
        },
        {
          id: 3,
          name: "contact",
          path: "/contact",
        },
        {
          id: 4,
          name: "about",
          path: "/about",
        },
      ],
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.icon-image {
  margin-left: 2%;
  width: 20px;
  height: 20px;
  margin-top: 2%;
  cursor: pointer;
}

.logo-image {
  width: 10%;
}

@media screen and (max-width: 600px) {
  .footer-sm {
    height: 60rem !important;
  }

  .logo-image-sm {
    width: 35%;
  }

  .footer-social-sm{
    padding-left: 9rem !important;
  }

  .logo-image-footer-support{
    width: 30% !important;
    margin-left: 135px !important;
  }

  .logo-image-footer {
    width: 80% !important;
    margin-left: 12% !important;
  }

  .margin-menu-footer-sm{
    margin-top: 10%;
  }

  .nav-menu-fnm{
    position: inherit !important;
    right: 2%;
  }

}
.footer-social{
  padding-left: 5rem;
}

.active {
  color: gold !important;
}

.footer {
  padding-top: 5rem;
  width: 100%;
  height: 30rem;
  background-color: #343a40;
}

.text-footer-h5 {
  color: gold;
}

.logo-image-footer {
  width: 100%;
}

.logo-image-footer-social-media {
  width: 4rem;
}

.link-footer {
  color: #bbb;
}

.link-footer:hover {
  color: #fff;
  text-decoration: none;
}

h1 {
  font-family: fantasy;
}
.logo-image-footer-support {
  width: 50%;
  margin-left: 70px;
}

.nav-menu-fnm{
  position: absolute;
  right: 2%;
}
</style>

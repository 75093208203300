<template>
  <div class="home">
    <b-carousel
      id="carousel-1"
      controls
      indicators
      no-animation
      background="#fff"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333"
    >
      <b-carousel-slide
        class="image-carrousel fnm-carrousel"
        img-src="@/assets/home/promo/f12022.png"
      >

        <b-link v-on:click="goTolink('https://www.youtube.com/@fnmracingteam8746')" target="_blank">
          <b-button >
            Proximas Transmissões
          </b-button>
        </b-link>

      </b-carousel-slide>
      <b-carousel-slide
        class="image-carrousel fnm-carrousel"
        img-src="@/assets/home/promo/wk1402.png"
      >

<!--        <b-link v-on:click="goTolink('https://www.youtube.com/@fnmracingteam8746')" target="_blank">
          <b-button >
            Acessar Transmissão
          </b-button>
        </b-link>-->
        <b-link v-on:click="goTolink('https://chat.whatsapp.com/JbRXqYcKb1aImjMWdfAuaN')" target="_blank">
          <b-button >
            Informações
          </b-button>
        </b-link>

      </b-carousel-slide>
      <b-carousel-slide
        class="image-carrousel"
        img-src="@/assets/home/1.png"
      ></b-carousel-slide>
      <b-carousel-slide
        class="image-carrousel"
        img-src="@/assets/home/2.png"
      ></b-carousel-slide>
      <b-carousel-slide
        class="image-carrousel"
        img-src="@/assets/home/3.png"
      ></b-carousel-slide>
      <b-carousel-slide
        class="image-carrousel"
        img-src="@/assets/home/4.png"
      ></b-carousel-slide>
      <b-carousel-slide
        class="image-carrousel"
        img-src="@/assets/home/5.png"
      ></b-carousel-slide>
      <b-carousel-slide
        class="image-carrousel"
        img-src="@/assets/home/6.png"
      ></b-carousel-slide>
    </b-carousel>
    <hr />
    <div class="col-12">
      <h1>Últimas Transmissões</h1>
      <div class="fnm-video-transmissao">
        <b-carousel
          img-width="1024"
          img-height="480"
          :interval="10000"
          controls
          indicators
        >
          <b-carousel-slide>
            <template v-slot:img>
              <iframe width="1024" height="480" src="https://www.youtube.com/embed/S5DUbh9WHlw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </template>
          </b-carousel-slide>

          <b-carousel-slide>
            <template v-slot:img>
              <iframe width="1024" height="480" src="https://www.youtube.com/embed/na9KTXBbZpc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </template>
          </b-carousel-slide>

          <b-carousel-slide>
            <template v-slot:img>
              <iframe width="1024" height="480" src="https://www.youtube.com/embed/Wu--sIT_0oY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </template>
          </b-carousel-slide>

        </b-carousel>
      </div>
    </div>
    <hr />
    <b-container class="bv-example-row">
      <h1>{{ $t("message.home.titleCard") }}</h1>
      <b-row>
        <b-col>
          <div
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            <b-card
              title="Formula 1"
              img-src="@/assets/home/card/f1-348x208.png"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-xl-5"
            >
              <b-card-text>
                {{ $t("message.home.cardF1") }}
              </b-card-text>

              <b-button
                href="/games/f1"
                variant="primary"
                class="btn-gold-fnm"
                >{{ $t("message.home.buttonCard") }}</b-button
              >
            </b-card>
          </div>
        </b-col>
        <b-col>
          <div data-aos="flip-up" data-aos-delay="200" data-aos-duration="1000">
            <b-card
              title="Assetto Corsa Competizione"
              img-src="@/assets/home/card/acc-348x208.png"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-6"
            >
              <b-card-text>
                {{ $t("message.home.cardACC") }}
              </b-card-text>

              <b-button
                href="/games/acc"
                variant="primary"
                class="btn-gold-fnm"
                >{{ $t("message.home.buttonCard") }}</b-button
              >
            </b-card>
          </div>
        </b-col>
        <b-col>
          <div
            data-aos="fade-left"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            <b-card
              title="Automobilista 2"
              img-src="@/assets/home/card/ams2-348x208.png"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-6"
            >
              <b-card-text>
                {{ $t("message.home.cardAMS2") }}
              </b-card-text>

              <b-button
                href="/games/ams2"
                variant="primary"
                class="btn-gold-fnm"
                >{{ $t("message.home.buttonCard") }}</b-button
              >
            </b-card>
          </div>
        </b-col>
        <b-col>
          <div
            data-aos="fade-right"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            <b-card
              title="iRacing"
              img-src="@/assets/home/card/ir-348x208.png"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-5"
            >
              <b-card-text>
                {{ $t("message.home.cardIR") }}
              </b-card-text>

              <b-button
                href="/games/ir"
                variant="primary"
                class="btn-gold-fnm"
                >{{ $t("message.home.buttonCard") }}</b-button
              >
            </b-card>
          </div>
        </b-col>
        <b-col>
          <div data-aos="flip-up" data-aos-delay="200" data-aos-duration="1000">
            <b-card
              title="rFactor 2"
              img-src="@/assets/home/card/rf2-348x208.png"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-4"
            >
              <b-card-text>
                {{ $t("message.home.cardRF2") }}
              </b-card-text>

              <b-button
                href="/games/rf2"
                variant="primary"
                class="btn-gold-fnm"
                >{{ $t("message.home.buttonCard") }}</b-button
              >
            </b-card>
          </div>
        </b-col>
        <b-col>
          <div
            data-aos="fade-left"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            <b-card
              title="Dirt Rally 2.0"
              img-src="@/assets/home/card/dirt-348x208.png"
              img-alt="Image"
              img-top
              tag="article"
              class="mb-4"
            >
              <b-card-text>
                {{ $t("message.home.cardDR2") }}
              </b-card-text>

              <b-button
                href="/games/dr2"
                variant="primary"
                class="btn-gold-fnm"
                >{{ $t("message.home.buttonCard") }}</b-button
              >
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
    };
  },
  methods: {
    goTolink (link) {
      window.open(link, '_blank');
    }
  }
};
</script>
<style scoped>
h1 {
  font-family: fantasy;
}

.card-body > a {
  margin-top: 2rem;
}

.card-body {
  max-width: 22rem;
  min-width: 20rem;
}

.card-img-top {
  height: 13rem;
}

.btn-gold-fnm {
  color: gold;
  background-color: #343a40;
  border-color: #343a40;
}

.embed-fnm{
  /*height: 200px;*/
}

.fnm-video-transmissao{
  background-color: #343a40!important;
}

</style>



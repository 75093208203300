import Vue from "vue";
import VueI18n from "vue-i18n";
import Languages from "./components/Languages";

Vue.use(VueI18n);

const messages = Languages.messages;

export default new VueI18n({
  locale: localStorage.Lang || "pt_BR",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: messages,
});
